/* -------------------------------
   Kanban Board & List Base Styles
----------------------------------*/
.kanban-board {
    padding: 16px;
  }
  
  .kanban-list {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    background-color: #fff;
  }
  
  /* -------------------------------
     Kanban Title Container Base Style
  ----------------------------------*/
.kanban-title-container {
   border-radius: 10px;
   padding: 8px;
   align-items: center;
   margin-bottom: 12px;
   /* Fallback background if no preset class is applied */
   background-color: #E0F7FA;
   /* Add shadow for a subtle 3D effect */
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
  
  /* -------------------------------
     Preset Pastel Background Classes
  ----------------------------------*/
  .pastel-1 { background-color: #FFCDD2; }  /* Pastel Red */
  .pastel-2 { background-color: #F8BBD0; }  /* Pastel Pink */
  .pastel-3 { background-color: #E1BEE7; }  /* Pastel Purple */
  .pastel-4 { background-color: #D1C4E9; }  /* Pastel Lavender */
  .pastel-5 { background-color: #C5CAE9; }  /* Pastel Blue */
  .pastel-6 { background-color: #BBDEFB; }  /* Pastel Light Blue */
  .pastel-7 { background-color: #B3E5FC; }  /* Pastel Sky Blue */
  .pastel-8 { background-color: #E0F7FA; }  /* Pastel Cyan */
  
  /* -------------------------------
     Kanban Title Text Styling
  ----------------------------------*/
  .kanban-title {
    border-radius: 8px;
    padding: 6px 10px;
    color: #333;
    cursor: pointer;
    margin: 0;
    /* A subtle translucent background */
  }
  