/* LeftColumnFontSizes.css */
.left-column-container {
    font-size: 0.85rem;
  }
  
  .left-column-title {
    font-size: 1.5rem;
  }
  
  .search-input {
    font-size: 0.8rem;
  }
  
  .select-all-text {
    font-size: 0.8rem;
  }
  
  .conversation-item-text {
    font-size: 1rem;
  }
  
  .icon-small {
    font-size: 1rem;
  }
  
  .message-button-text {
    font-size: 1rem;
  }