.userTab {
    border-collapse: collapse;
    width: 100%;
}

.userTab td,
.userTab th {
    border: 1px solid #ddd;
    padding: 12px;
}

.userTab tr:hover {
    background-color: #ddd;
}

.userTab th {
    padding: 10px;
    text-align: center;
    background-color: #181b26;
    color: white;
}

.actions {
    text-align: center;
}

/* Modern, minimalistic table styles */
.table-responsive {
    border-radius: 8px;
    overflow: hidden;
}

.table {
    margin-bottom: 0;
    background-color: white;
    border-collapse: collapse;
}

.table th {
    font-weight: 600;
    color: #495057;
    border-bottom-width: 1px;
    background-color: #f8f9fa;
    padding: 12px 16px;
}

.table td {
    padding: 12px 16px;
    vertical-align: middle;
    border-top: 1px solid #f2f2f2;
}

.table tr:hover {
    background-color: #f9f9f9;
}

.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
}

.btn-outline-primary {
    color: #3498db;
    border-color: #3498db;
}

.btn-outline-primary:hover {
    background-color: #3498db;
    color: white;
}

.btn-outline-danger {
    color: #e74c3c;
    border-color: #e74c3c;
}

.btn-outline-danger:hover {
    background-color: #e74c3c;
    color: white;
}