.home-layout {
  display: flex;
  min-height: calc(100vh - 80px); /* Ajusta según sea necesario */
}

.sidebar-bar {
  background-color: #F5F2EE; 
  width: 250px;
  padding: 20px;
  position: fixed;
  top: 0;
  bottom: 0;
  overflow-y: auto; /* Si deseas que tenga scroll interno */
  margin-top: calc(6vh); /* Ajusta según sea necesario */
}

.sidebar-bar .btn {
  background-color: transparent; /* o variant="light" en React-Bootstrap */
  border: none; 
  text-align: left;
  padding: 0.75rem 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.sidebar-bar .btn:hover {
  background-color: #eae7e3; /* color suave al pasar el mouse */
}


.sidebar-bar .btn .icon {
  margin-right: 8px; /* espacio entre el ícono y el texto */
}

/* Si quieres que el botón "Desactivar cuenta" se fije al final */
.sidebar-bar .mt-auto {
  margin-top: auto !important;
}

.main-content {
  margin-left: 250px; /* Asegura que el contenido no quede debajo del sidebar */
  width: 100%;

}

/* Media query para dispositivos móviles */
@media (max-width: 991px) {
  .sidebar-bar {
    display: none;
  }
  
  .main-content {
    margin-left: 0; /* Quita el margen cuando el sidebar se oculta */
  }
}