/* AdvancedFiltersStyles.css */

/* Container-wide font size */
.advanced-filters-container {
    font-size: 0.85rem;
  }
  
  /* Main title (e.g., "Filtros avanzados") */
  .advanced-filters-title {
    font-size: 1rem;
  }
  
  /* Subheadings (e.g., "Fechas", "Estados de conversación") */
  .advanced-filters-subtitle {
    font-size: 1.1rem;
  }
  
  /* Text inside smaller descriptive sections, paragraphs, etc. */
  .advanced-filters-item-text {
    font-size: 1rem;
  }
  
  /* (Optional) For specific buttons if you want them smaller or bigger */
  .advanced-filters-button-text {
    font-size: 0.9rem;
  }