.blur-effect {
    filter: blur(3px);
    /* Adjust the pixel value to control the amount of blur */
}

.logo-size {
    height: 40px;
}

.pt-main {
    padding-top: 4.5em;
}

#topbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    /* Adjust the z-index as needed */
}

.version-text {
    text-align: center;
    margin-top: 1em;
    font-size: 1rem;
    color: #949393;
}

.px-custom-conversation {
    padding-right: 30%;
    padding-left: 30%;
}

.btn-add {
    position: fixed;
    bottom: 20px;
    right: 0;
    width: 100%;
    z-index: 3;
    /* Adjust the z-index as needed */
}

.bg-gray {
    background-color: #eaeaea;
}

.bg-custom-1 {
    border-radius: 0.7rem;
    background: linear-gradient(40deg, #0d4753, #13a06c, #13a06c, #0d4753);
    background-size: 200% 200%;
    transition: background-position 0.5s ease, transform 0.5s ease;
    /* Add transform to the transition */
    background-position: 0% 50%;
}

.bg-custom-2 {
    border-radius: 0.7rem;
    background: linear-gradient(40deg, #3c445e, #11989f, #11989f, #3c445e);
    background-size: 200% 200%;
    transition: background-position 0.5s ease, transform 0.5s ease;
    /* Add transform to the transition */
    background-position: 0% 50%;
}

.bg-custom-3 {
    border-radius: 0.7rem;
    background: linear-gradient(40deg, #181926, #3c445e, #3c445e, #181926);
    background-size: 200% 200%;
    transition: background-position 0.5s ease, transform 0.5s ease;
    /* Add transform to the transition */
    background-position: 0% 50%;
}

.bg-custom-4 {
    border-radius: 0.7rem;
    background: linear-gradient(40deg, #14184c, #3c445e, #3c445e, #14184c);
    background-size: 200% 200%;
    transition: background-position 0.5s ease, transform 0.5s ease;
    /* Add transform to the transition */
    background-position: 0% 50%;
}

.bg-custom-5 {
    border-radius: 0.7rem;
    background: linear-gradient(40deg, #4a4a4a, #b8b8b8, #ffffff, #515151);
    background-size: 200% 200%;
    transition: background-position 0.5s ease, transform 0.5s ease;
    /* Add transform to the transition */
    background-position: 0% 50%;
}

.bg-custom-6 {
    border-radius: 0.7rem;
    /* background: linear-gradient(40deg, #4a4a4a, #b8b8b8, #ffffff, #515151); */
    background: linear-gradient(45deg, #fb546c, #ff7b8a, #ff8a9a, #fb546c);
    background-size: 200% 200%;
    transition: background-position 0.5s ease, transform 0.5s ease;
    /* Add transform to the transition */
    background-position: 0% 50%;
}

.bg-custom-conversation {
    border-radius: 0.7rem;
    background: linear-gradient(40deg, #d0d0d0, #d0d0d0, #d0d0d0, #9d9d9d);
    background-size: 200% 200%;
    transition: background-position 0.5s ease, transform 0.5s ease;
    /* Add transform to the transition */
    background-position: 0% 50%;
}

.bg-custom-1:hover,
.bg-custom-2:hover,
.bg-custom-4:hover,
.bg-custom-3:hover,
.bg-custom-5:hover,
.bg-custom-6:hover,
.bg-custom-conversation:hover {
    cursor: pointer;
    background-position: 100% 50%;
    transform: scale(1.02);
    /* Increase the size a bit */
}

.clickable {
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    /* Adjust the duration and timing function as needed */
}

.clickable:hover {
    transform: scale(1.02);
    background-color: #9698a010;
}

.modal-content {
    border: solid lightgrey 0px !important;
    border-radius: 1rem !important;
    background: rgba(255, 255, 255, 0.9) !important;
    /* Fondo blanco semitransparente */
    backdrop-filter: blur(4px) !important;
    /* Efecto de desenfoque */
    -webkit-backdrop-filter: blur(4px) !important;
}

/* Style the icons - hidden by default, with transition for smooth effect */
.cita-option i {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.5s, opacity 0.2s linear;
}

/* Show icons when hovering over the specific parent row */
.row:hover>.cita-option i {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.2s linear;
}

.btn-base:hover>.cita-option i {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.2s linear;
}


.w-10 {
    width: 10%;
}
.w-20 {
    width: 20%;
}
.w-30 {
    width: 30%;
}
.w-40 {
    width: 40%;
}
.w-50 {
    width: 50%;
}
.w-55 {
    width: 50%;
}
.w-60 {
    width: 60%;
}
.w-70 {
    width: 70%;
}
.w-80 {
    width: 80%;
}
.w-90 {
    width: 90%;
}
.w-100 {
    width: 100%;
}

.me-date {
    margin-right: 70px;
}

.me-date2 {
    margin-left: 70px;
}

.user_image,
.renata_image {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.timestamp {
    font-size: 0.8rem;
    margin-top: 0.25rem;
  }
  
  /* Adjust message alignment */
  .d-flex.align-items-end {
    flex-wrap: nowrap;
  }
  
  /* Ensure images do not cause overflow */
  .user_image.me-2,
  .renata_image.ms-2 {
    flex-shrink: 0;
  }


.message-item-1,
.message-item-2 {
  border-radius: 0.7rem;
  transition: background-color 0.1s ease-in-out, transform 0.2s ease-in-out;
  max-width: 60%;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.message-item-1 {
  background-color: #f2f2f2;
}

.message-item-2 {
  background-color: #54afece9;
}

.conversation-item {
    transition: background-color 0.1s ease-in-out, transform 0.2s ease-in-out;
    /* Adjust the properties and timing function as needed */
}

.conversation-item:hover {
    background-color: #f2f2f2;
    cursor: pointer;
}

.conversation-item.selected-item {
    background-color: #f2f2f2;
    border-color: #181926 !important;
}

.conversation-scroll {
    position: relative;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    background: white; /* Ajusta según el fondo de tu contenedor */
}


  
  /* Pseudo-elemento para el efecto de desvanecimiento en la parte superior */


.message-container {
    padding: 0.5rem 1rem;
    width: 100%;
  }

.conversation-box {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.conversation-box-my-conversations {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.conversation-scroll-my-conversations {
    overflow-x: hidden; 

}

/* Ocultar el ícono por defecto, pero mantener su espacio */
.bi-circle.hide {
    visibility: hidden;
}

/* Mostrar el ícono cuando el elemento de la conversación esté en hover */
.conversation-item:hover .bi-circle {
    visibility: visible;
}

/* Mantener el ícono visible si el elemento tiene la clase `selected` */
.conversation-item.selected .bi-circle {
    visibility: visible;
}

.icon-upload {
    font-size: xx-large;
}


@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.large-checkbox {
    width: 30px;
    height: 30px;
    transform: scale(1.5);
    cursor: pointer;
}

.form-control-custom {
    border: 0 !important;
}

.form-control-custom:hover,
.form-control-custom:focus {
    border: 0 !important;
    outline: none !important;
    /* Remove outline */
    box-shadow: none !important;
    /* Remove Bootstrap's glow effect */
}

.accordion-collapse {
    transition: height 0.35s ease;
    overflow: hidden;
}

.btn-outline-dark:hover {
    background-color: rgba(0, 0, 0, 0.131) !important;
    color: #181926 !important;
}

.border-radius-custom-1 {
    border-bottom-left-radius: 40px !important;
    border-top-left-radius: 40px !important;
}

.border-radius-custom-2 {
    border-bottom-right-radius: 40px !important;
    border-top-right-radius: 40px !important;
}

.border-radius-custom-3 {
    border-radius: 40px !important;
}

.w-150-px {
    width: 110px;
    text-align: center;
}

.custom-height-categoria {
    height: 130px;
}

.brightness-hover {
    filter: brightness(1);
    transition: all 0.3s ease; /* Apply transition to all properties */
}

.brightness-hover:hover {
    filter: brightness(0.95);
}

.bg-transparent {
    background-color: transparent !important;
}


.border-orange {
    border-left: 3px solid rgb(229, 124, 78) !important; /* Ensure 'solid' is specified */
}

.category-badge {
    display: inline-flex; /* Makes the div only as wide as the content */
    border: 1px solid #f0e68c; /* Border color */
    background-color: #fff8dc; /* Background color */
    border-radius: 1.5rem; /* Rounded borders */
    padding: 2px 8px; /* Padding adjusted for better spacing */
    align-items: center;
}
  .category-icon {
    margin-right: 1rem; /* Ajusta el margen derecho para más separación */
  }

.b-vertical{
    display: block;
    padding: .125rem 0 .125rem .75rem;
    text-decoration: none;
    border-left: .125rem solid transparent;
    border-left-color: #000;
}

.bg-modal-cont .modal-content{
    background-color: #fff!important;
}

#input-img > input {
    visibility:hidden;
    width:0;
    height:0
}

.point{
    cursor: pointer;
}

.mw-50{
    max-width: 49%!important;
}

.mw-50:nth-child(odd){
    margin-right: 0.25rem;
}

.btn-size{
    font-size: 0.84rem!important;
}

.gray-skill-option {
    background-color: #dadada;
    height: 120px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 2px solid transparent;
    transition: border 0.1s ease;
}

.gray-skill-option:active {
    border: 1.5px solid #2f2f2f;
}

.habilidad-icon {
    width: 50px;
}

.selected {
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.7);
    transition: box-shadow 0.3s ease-in-out;
    background-color: #cecece !important;
}

.switch-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 7px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4caf50; /* Color cuando está activado */
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.i-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0 0 2px #eede64;
  color: #000;
  background-color: #eede64; 
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation: rotate 1s linear infinite !important;
}

.shadow-payment {
    box-shadow: 0 0 40px rgba(34, 66, 77, 0.521);
    z-index: 1000;
}

.bg-payment-custom {
    background-color: #eaeaea;
    border-radius: 45px;
    box-shadow: 0 0 40px rgba(91, 91, 91, 0.317);
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-bottom: 5vh;
    margin-top: 5vh;
}

.renata-loader {
    max-height: 150px;
}

.renata-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.bouncing-dots {
    display: flex;
    justify-content: space-between;
    width: 60px;
}

.dot {
    width: 15px;
    height: 15px;
    background-color: #FF5C35;
    border-radius: 50%;
    animation: bounce 1.5s infinite;
}

.dot:nth-child(1) {
    animation-delay: 0s;
}

.dot:nth-child(2) {
    animation-delay: 0.3s;
}

.dot:nth-child(3) {
    animation-delay: 0.6s;
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
}

.conversation-rounded {
    border-top-left-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}



.conversation-rounded-2 {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
}


.fade-enter {
    opacity: 0;
    transform: translateY(20px);
  }
  
  .fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
  }
  
  .fade-exit {
    opacity: 1;
    transform: translateY(0);
  }
  
  .fade-exit-active {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 300ms, transform 300ms;
  }

  .conversation-list {
    transition: margin-bottom 0.2s ease;
    border-radius: 20px;
  }
  
  .conversation-list.with-button {
    margin-bottom: 80px; /* Adjust to the height of the button plus margins */
  }
  
  .conversation-list.no-button {
    margin-bottom: 20px;
  }

  .fade-top {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    pointer-events: none;
    z-index: 10;
  }

  .fade-top-preview {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    pointer-events: none;
    z-index: 10;
  }
  .bi-question-circle {
    cursor: pointer;
  }
  
  /* Settings page styles */
  .settings-sidebar {
    position: sticky;
    top: 80px; /* Ajusta este valor según la altura de tu topbar */
    overflow-y: visible;
    max-height: calc(100vh - 100px);
  }
  .settings-content {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    border-radius: 0.5rem;
  }
  
  /* Styles for the assistants tab - make only the content area scroll */
  .settings-content .card-body {
    max-height: calc(100vh - 140px);
    overflow-y: auto;
  }
  
  /* This ensures that section titles stay fixed while the content scrolls */
  .settings-content .fw-bold.text-dark.my-0 {
    position: sticky;
    top: 0;
    background-color: white;
    padding-top: 10px;
    z-index: 1;
  }
  
  /* Add shadow border rounded style to all cards in settings */
  .settings-content .card {
    border-radius: 0.5rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  }
  
  /* Fix dropdown menu in settings */
  .settings-content .dropdown-menu.show {
    display: block;
    z-index: 10;
  }
