.p-login {
    padding-top: 6em;
}

.centered-placeholder::placeholder {
    text-align: center;
}

.centered-input-container {
    text-align: center;
    display: flex;
    justify-content: center;
}

.loading {
    max-height: 18px;
}



.checkout-form {
    max-width: 500px;
    margin: auto;
}

.card-element-wrapper {
    padding: 10px !important;
    border: 1px solid #ced4da !important;
    border-radius: 12px !important;
    background-color: #f8f9fa !important;
    transition: box-shadow 0.3s, border-color 0.3s;
}

.card-element {
    width: 100%;
}


@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@100..800&display=swap');

body {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    background-color: rgb(253, 253, 253);
    overflow-x: hidden;
    /* Prevents horizontal scroll globally */
    width: 100%;
}

.rounded {
    border-radius: 16px !important;
}

.font-light {
    font-weight: 100 !important;
}

.font-regular {
    font-weight: 400 !important;
}

.font-medium {
    font-weight: 500 !important;
}

.font-semibold {
    font-weight: 600 !important;
}

.font-bold {
    font-weight: 700 !important;
}

.font-extrabold {
    font-weight: 800 !important;
}

.main-container {
    margin-left: 280px;
    margin-top: 50px;
}

/*BG Colors*/

.bg-pink-light {
    background-color: #F5E2F2;
    color: #964B98;
}

.bg-yellow-light {
    background-color: #fbf8d3;
    color: #987e4b;
}

.bg-green-light {
    background-color: #BDE6D7;
    color: #35797E;
}

.bg-blue-light {
    background-color: #B7C9DE;
    color: #24466C;
}

.bg-gray-light {
    background-color: #EAE7E3;
}

.bg-gray-light-2 {
    background-color: #F7F7F7;
}

.form-control {
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
    border: 1px solid rgb(220, 220, 220);
}

.form-control:focus {
    border-color: #aadfd0 !important;
    /* Change border color */
    box-shadow: 0 0 0 0.2rem #40b29255 !important;
    /* Change outer glow */
    outline: none !important;
}


.dropdown-item {
    padding: 8px 12px;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
}

.dropdown-menu {
    background-color: rgba(243, 243, 243, 0.552) !important;
    border: 1px solid rgb(220, 220, 220) !important;
    backdrop-filter: blur(10px) !important;
    border: none !important;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
}

.dropdown-item:hover {
    background-color: #d1d1d145;
}

.dropdown-item:active {
    background-color: #d7d7d7 !important;
    color: black !important;
}

.container-login-outer {
    height: 100%;
    margin: 0;
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: #ACCECE;
    overflow: hidden;
    
}

.background-login {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1; /* ensures the background stays behind your content */
    background: #F5F2EE;
  }
  
  .background-login svg {
    width: 100%;
    height: 100%;
    display: block;
  }

.wave {
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: auto;
    transform: rotate(180deg);
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    flex-direction: column;
    min-height: 100vh;
    overflow-y: auto;    
}

.register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    flex-direction: column;
    min-height: 140vh;
    overflow-y: auto;    
}

.login-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.login-logo {
    width: 250px;
    display: block;
    position: relative;
    z-index: 10;
    margin-bottom: 20px;
}

.google-icon {
    width: 20px;
}

.title-login {
    font-size: 30px;
}

.login-box {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px) !important;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 100%;
    max-width: 500px;
}

.login-box h2 {
    margin-bottom: 1rem;
}

.px-custom-login {
    padding-right: 100px;
    padding-left: 100px;
}

.text-olvidaste {
    color: #24466C;
}

.google-btn,
.login-btn,
.forgot-password,
.register-btn {
    width: 100%;
    padding: 0.8rem;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    margin-top: 1rem;
    color: #333 !important;
}

.google-btn {
    margin-top: 16px;
    margin-bottom: 16px;
    background-color: #EFEEEE;
    color: rgb(0, 0, 0);
    border: #333;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.google-btn:hover {
    background-color: #d8d8d8;
}

.google-logo {
    font-size: 1.3rem;
    font-weight: bold;
}

.login-btn {
    background-color: #6AD3B5;
    color: white;
    font-size: 1rem;
}

.login-btn:hover {
    background-color: #5DAF97;
}

.forgot-password {
    background: none;
    color: #24466C;
    font-size: 0.9rem;
    text-decoration: underline;
    margin-top: 0.5rem;
    cursor: pointer;
}

.forgot-password:hover {
    color: #24466C;
}

.register-link {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.register-link span {
    margin-right: 0.5rem;
}

.register-btn {
    background: none;
    color: #24466C;
    font-size: 1rem;
    border: none;
    text-decoration: underline;
    cursor: pointer;
}

.register-btn:hover {
    color: #24466C;
}

.separator {
    margin: 1rem 0;
    border-top: 1px solid #ccc;
}

.centered-placeholder {
    text-align: center;
}

.centered-placeholder::placeholder {
    text-align: center;
    color: #dddddd !important;
}

label {
    text-align: left;
    display: block;
    margin-bottom: 0.3rem;
    font-size: 0.9rem;
}

.continue-btn {
    width: 100%;
    padding: 0.8rem;
    border: none;
    border-radius: 50px;
    background-color: #6AD3B5;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 4rem;
}

.continue-btn:hover {
    background-color: #5DAF97;
}

.setup-box {
    width: 100%;
    max-width: 600px;
    padding: 4rem;
}

.setup-box h2,
.setup-box label,
.setup-box input,
.setup-box .continue-btn {
    margin-bottom: 1.5rem;
}

.license-section {
    text-align: center;
    margin-top: 2rem;
}

.license-text {
    font-size: 1rem;
    color: #333;
    white-space: nowrap;
}

.license-price {
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 0.5rem;
}

.user-counter {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    margin: 1rem 0;
}

.user-counter button {
    background-color: #24466C;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 50px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}

.user-counter button:hover {
    background-color: #24466C;
}

.user-count-box {
    border: 2px solid black;
    border-radius: 50px;
    padding: 0.5rem 1.5rem;
    font-size: 1.2rem;
    font-weight: bold;
    background: white;
    text-align: center;
    min-width: 80px;
}



.payment-btn {
    width: 100%;
    padding: 0.8rem;
    border: none;
    border-radius: 50px;
    background-color: #6AD3B5;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 1rem;
}

.payment-btn:hover {
    background-color: #5DAF97;
}

.coupon-btn {
    background: none;
    color: #24466C;
    font-size: 1rem;
    border: none;
    text-decoration: underline;
    cursor: pointer;
}

.coupon-btn:hover {
    color: #24466C;
}

.db-options {
    display: flex;
    gap: 15px;
    justify-content: center;
    margin-top: 20px;
}

.db-card {
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.db-card:hover {
    transform: scale(1.1);
}

.db-card img {
    width: 50px;
    height: 50px;
    margin-bottom: 5px;
}

.add-new {
    font-size: 40px;
    font-weight: bold;
    background: #f0f0f0;
    color: #666;
}

.Mui-completed {
    color: #24466C !important;
}

.pago-pricing {
    color: #1b5d52;
}

.pago-text-small {
    color: #1b5d52;
    font-size: 16px;
}

.box-payment {
    box-shadow: 0px 2px 7px rgba(13, 160, 45, 0.3);
}

.box-payment-2 {
    box-shadow: 0px 2px 7px rgba(20, 4, 103, 0.3);
}

.btn-disabled-register {
    background-color: #666 !important;
}