.navbar-glass {
    background-color: #F5F2EE; 
    /* Fondo blanco semitransparente */
    backdrop-filter: blur(10px);
    /* Efecto de desenfoque */
    -webkit-backdrop-filter: blur(10px);
}

.mobile-only {
    display: block;
}

@media (min-width: 768px) {
    .mobile-only {
        display: none;
    }
}